@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600&display=swap');
@import 'styles/_colors.scss';

* {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.h2-no-margin {
  margin-block-start: 0;
  margin-block-end: 0;
}

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.jcsb {
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

.screen {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%
}

#root,
#tableContainer {
  height: 100%;
}

.bold {
  font-weight: 700;
}

.gray {
  color: $gray;
}

.green {
  color: $lightgreen;
}

/////////////
// Dimensions
/////////////

.m0 {
  margin: 0;
}

.mb0 {
  margin-bottom: 0;
}

.mt0 {
  margin-top: 0;
}

.mt1 {
  margin-top: 1rem;
}

.mr1 {
  margin-right: 1rem;
}

.ml1 {
  margin-left: 1rem !important;
}

.mt5p {
  margin-top: 5px;
}

.p20 {
  padding: 20px;
}

/////////////
// Components
/////////////

#productsScreenRight {
  width: 395px;
}

#productsScreenLeft {
  width: calc(100% - 395px)
}

#coverImg {
  width: 100%;
}

#combinationModal,
#infoModal,
#scannerInstructionModal {
  padding: 0px 24px;
}

.scannerInstruction-container {
  justify-content: center;
}

.scannerInstruction-list li {
  line-height: 2em;
}

.scannerInstruction-image {
  width: 250px;
}

.products-container {
  justify-content: center;
}

.product-flex-item {
  width: 275px;
  background: #fff;
  margin-bottom: 20px;
  align-items: center;
  margin-right: 2%;
  padding-top: 20px;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  position: relative;
  height: 395px;
}

.product-flex-item.selected {
  border: 2px solid #00973B;
}

.product-flex-item .image-container {
  height: 180px;
  display: flex;
  align-items: center;
  position: relative
}

.product-flex-item img {
  width: 160px
}

.category-flex-item {
  height: auto;
}

.product-details {
  width: 100%;
  padding: 0 30px;
}

.panel-product-title {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.panel-product-weight {
  font-size: 16px;
  font-weight: 700;
  color: #aaa
}

.product-price-info {
  width: 100%;
  padding: 0 25px 25px 25px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
}

.info-button {
  padding: 5px 20px;
  font-size: 32px;
  background: #00973B;
  border-radius: 50%;
  color: white;
  font-weight: 700;
  cursor: pointer;
}

.product-price {
  font-size: 24px;
  font-weight: 700
}

.product-price-nodiscount {
  font-size: 20px;
  color: #CCCCCC;
  text-decoration: line-through;
  font-weight: 700;
  text-align: right;
  width: 100%;
}

.product-price-discount {
  font-size: 24px;
  color: #BE1622;
  margin-left: 30px;
  font-weight: 700
}

;

.product-item-labels {
  height: 20px;
  bottom: 5px;
  left: 5px;
}

;

.product-item-labels>* {
  background: #FFF0B3;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  padding: 2px;
  margin-right: 5px;
}

.product-item-labels {
  span.sold-out {
    background: #ffcdd2;
  }
}

button {
  font-size: 16px !important;
  padding: 22px !important;
  font-weight: 700;
}

#infoModal .product-flex-item {
  border: none;
}

#infoModal .product-flex-item img {
  width: 160px
}

#infoModal .product-flex-item .image-container {
  height: 230px;
}

/////////////
// Overrides
/////////////

.MuiButtonBase-root.kiosk-lg-btn {
  width: auto;
  padding: 36px 66px;
  margin-top: 50px;
  font-size: 30px;
  margin-left: 1px;
  text-transform: none;
}

.MuiButtonBase-root.confirm-btn {
  width: 170px;
}

.MuiButton-contained.green-btn {
  background-color: #00973B !important;
  color: white;
}

.MuiButton-contained.darkgreen-btn {
  background-color: $darkgreen !important;
  color: white;
}

.MuiButton-contained.red-btn {
  background-color: $applered !important;
  color: white;
}

.hg-theme-default .hg-button {
  height: 60px !important; 
}