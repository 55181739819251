$primary_light: #4791db;
$primary_main: #1976d2;
$primary_dark: #115293;

$info_light: #64b5f6;
$info_main: #2196f3;
$info_dark: #1976d2;

$orange: #f49200;

$gray: #575756;
$lightgray: #cccccc;
$lightgreen: #95C11F;
$darkgreen: #00973B;
$applered: #ef5350;

$black: #222222;